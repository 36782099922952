import React, { createContext, useState } from "react"

interface ScrollContextType {
  scrollTo: string
  handleSetScrollTo: any
}

const ScrollContext = createContext<ScrollContextType>({
  scrollTo: ``,
  handleSetScrollTo: null,
})
const ScrollProvider: React.FC = ({ children }) => {
  const [scrollTo, setScrollTo] = useState<string>(``)

  const handleSetScrollTo = (id: string): void => {
    setScrollTo(id)
    setTimeout(() => {
      setScrollTo(``)
    }, 1200)
  }

  return <ScrollContext.Provider value={{ scrollTo, handleSetScrollTo }}>{children}</ScrollContext.Provider>
}
export default ScrollContext
export { ScrollProvider }
