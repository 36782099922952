/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { ScrollProvider } from "./src/context/scroll-context"

export const onClientEntry = async () => {
  if (typeof window.IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

export const shouldUpdateScroll = () => false

export const wrapRootElement = ({ element }) => <ScrollProvider>{element}</ScrollProvider>
